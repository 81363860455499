import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './pvp.scss';
import { Alert, Col, Row, Table } from 'react-bootstrap';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkePVPMech: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page pvp-nikke'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>PvP Arena Mechanics</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_pvp.jpg"
            alt="PvP Arena Mechanics"
          />
        </div>
        <div className="page-details">
          <h1>PvP Arena Mechanics</h1>
          <h2>Everything you need to know about PVP in NIKKE.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content with-italics">
        <SectionHeader title="Foreword" />
        <p>
          This guide has been created by <strong>awesome21snake</strong>. If you
          have any feedback about the guide, catch him on our Discord!
        </p>
        <SectionHeader title="The Basics" />
        <p>
          This section was heavily inspired by the following source:{' '}
          <a
            href="https://bbs.nga.cn/read.php?tid=35461373&_fp=2)&rand=595"
            target="_blank"
            rel="noreferrer"
            className="social-media-link"
          >
            NGABBS-pvpguide
          </a>
          . I thank them for their hard work researching Nikke’s PvP Arena. This
          was by far the most informative PvP guide I had found. I would also
          like to thank the maid team of the Nikke Community Server for defining
          and simplifying this section, namely:
          <strong> Omegamk19, zixk98, Scy_v4x, and cleancascade</strong>.
          Additionally I would like to thank <strong>Keripo</strong> for his
          research in PvP.
        </p>
        <p>
          The following points explain the basic ideas we should know about
          Nikke PvP.
        </p>
        <SectionHeader title="The Very Basics" />
        <p>
          A PvP round consists of two teams. One attacker (ATK) team and One
          defender (DEF) team. Whichever team has all of its Nikkes die first,
          loses. If both teams die at the same time, the DEF team wins. A PvP
          round lasts 5 minutes. If the round isn’t over even after 5 minutes,
          the DEF team is automatically given the victory of that round. Now
          that we know what a win and loss in PvP is, we can move on to the
          actual mechanics.
        </p>
        <SectionHeader title="Basic Targeting and Positioning" />
        <h5>Info #1</h5>
        <p>
          Knowing the positions of both ATK and DEF teams is important in PvP.
          You’ll find in the photos shown below, positions labelled P1, P2, P3,
          P4, P5 for ATK (below) and DEF (above).
        </p>
        <p>
          During the PvP round, the positioning for the attacking team will be
          the same, but for the defending team it will be flipped (as shown in
          the photo below).
        </p>
        <Row xs={1} xl={2} xxl={2}>
          <Col>
            <StaticImage
              src="../../../images/nikke/pvp/pvp_13.webp"
              alt="PVP"
            />
          </Col>
          <Col>
            <StaticImage
              src="../../../images/nikke/pvp/pvp_14.webp"
              alt="PVP"
            />
          </Col>
        </Row>
        <h5>Info #2</h5>
        <p>
          The following table shows the normal targeting for each weapon type:
        </p>
        <Table striped bordered responsive className="table-with-p">
          <thead>
            <tr>
              <th colSpan={5}>
                <p className="center">
                  <strong>Normal Targeting for each Weapon Type</strong>
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Weapon Types</strong>
                </p>
              </td>
              <td colSpan={2}>
                <p>
                  <strong>While Attacking</strong>
                </p>
              </td>
              <td colSpan={2}>
                <p>
                  <strong>While Defending</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>
                <p>
                  <span>First Shot</span>
                </p>
              </td>
              <td>
                <p>
                  <strong>Second Shot Onwards*</strong>
                </p>
              </td>
              <td>
                <p>
                  <span>First Shot</span>
                </p>
              </td>
              <td>
                <p>
                  <strong>Second Shot Onwards*</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>SMG</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P5</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P1</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P5</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P1</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>MG</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P5</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P1</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P5</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P1</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>AR</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P5</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P1</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P5</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P1</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>RL</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P1</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P1</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P1</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P1</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>SG</strong>
                </p>
              </td>
              <td>
                <p>
                  <span>P5</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P1</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P5</span>
                </p>
              </td>
              <td>
                <p>
                  <strong>P5**</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>SR</strong>
                </p>
              </td>
              <td>
                <p>
                  <span>P5</span>
                </p>
              </td>
              <td>
                <p>
                  <strong>P5**</strong>
                </p>
              </td>
              <td>
                <p>
                  <span>P1</span>
                </p>
              </td>
              <td>
                <p>
                  <span>P1</span>
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                <p>
                  <strong>
                    *Second Shot Onwards is assumed as where these weapon types
                    will{' '}
                  </strong>
                  <strong>normally focus</strong>
                </p>
                <p>
                  <strong>
                    **SGs will focus on P5 while Defending. SRs will focus on P5
                    while Attacking.
                  </strong>
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
        <p>This is very very important to remember while team building.</p>
        <h5>Info #3</h5>
        <p>
          RLs will cause AOE damage to the left and right sides of the target.
          If you attack P1, positions 1 and 2 will be damaged; if you attack P2,
          positions 1, 2 and 3 will be damaged, and so on. Hence you should keep
          the following image in mind while positioning your Nikkes:
        </p>
        <StaticImage src="../../../images/nikke/pvp/pvp_15.webp" alt="PVP" />
        <p>
          Source for image -{' '}
          <a
            href="https://forum.gamer.com.tw/Co.php?bsn=36390&sn=85453&subbsn=5&bPage=0"
            target="_blank"
            rel="noreferrer"
            className="social-media-link"
          >
            here
          </a>
          .
        </p>
        <SectionHeader
          title="Targeting Shenanigans"
          subtitle="How and when targeting changes."
        />
        <h5>Info #4</h5>
        <p>A Nikke will change targets if:</p>
        <ul>
          <li>She reloads</li>
          <li>Her weapon changes (Snow White, Maxwell, Laplace)</li>
        </ul>
        <p>Or if the Nikke she's targeting:</p>
        <ul>
          <li>Hides behind cover,</li>
          <li>Dies,</li>
          <li>The target's Taunt ends.</li>
        </ul>
        <p>
          Nikkes who are <strong>‘in cover’</strong> or dead get skipped when
          choosing new targets; <strong>‘In cover’</strong> includes reloading,
          getting stunned, and interval between every SR and RL shot. This will
          be discussed more in our <a href="#targeting">Targeting Section</a>.
        </p>
        <h5>Info #5</h5>
        <p>
          RL and SR units are sometimes not suitable in the P1 position. This is
          due to a phenomenon called <strong>‘Target Skipping’</strong>. The
          normal attack intervals of RL and SR will make them ‘enter cover’,
          which will allow the focusing party to re-select the target and skip
          them in most cases. This is still a random event. The Nikke is either
          skipped or not, depending on what the game feels like choosing. This
          is discussed more in our <a href="#targeting">Targeting Section</a>.
        </p>
        <h5>Info #6</h5>
        <p>
          Based on the conclusion of the previous point, be careful when using
          RL Nikkes as P1 tankers, mainly: Noise and Noah. They have a high
          probability of allowing Target Skipping to happen, making AR/SG/SMG/MG
          Nikkes in P2/P3/P4 (and in some cases, even P5) extremely vulnerable
          to dying. This also gives the enemy RL increased burst gen due to them
          hitting 3 targets instead of the usual 2.
        </p>
        <h5>Info #7</h5>
        <p>
          <strong>Targeted skills can be taunted</strong>, such as
          Anis:Sparkling Summer's skill 2, Power’s or Pepper’s nuke "against the
          enemy with the highest ATK", Brid’s nuke "against the enemy with the
          highest DEF power”, etc. If these skills are taunted before being
          triggered, they will target the taunter.
        </p>
        <h5>Info #8</h5>
        <p>
          Currently, teams 2 and 3 in SP arena will have abnormal targeting
          RARELY. In this, the position of the last Nikke focus fired on the
          previous team gets targeted in the following round. This will be
          discussed further in the Targeting section under{' '}
          <strong>“Residual Targeting”</strong>, a term coined by us to explain
          this phenomenon.
        </p>
        <SectionHeader
          title="Burst Generation or Burst Gen"
          subtitle="Know the basics of burst energy generation."
        />
        <h5>Info #9</h5>
        <p>
          RL hitting P1 can cause AOE damage to 4 targets, which are P1+P2+P1
          cover+P2 cover, so the burst gen of that particular RL is 4× her
          normal burst gen value, and the same applies to hitting P5; RL hitting
          P2 can cause AOE damage to 6 targets. Therefore, it is 6× the normal
          burst gen of the Nikke, and the same applies to hitting P3 and P4.
        </p>
        <h5>Info #10</h5>
        <p>
          Some Nikkes have skills that can give extra burst gen, such as
          Scarlet’s skill 2, Jackal’s skill 1, Harran’s skill 1, Modernia’s
          skill 1, etc. The burst gen value that is given from these skills each
          time these skills are triggered are the base burst gen value of these
          Nikkes: Scarlet: 0.45, Jackal: 3.55, Modernia: 0.05, Harran: 2.9, etc
        </p>
        <h5>Info #11</h5>
        <p>
          Nihilister, A2 and Pascal are special Nikkes in their weapon types.
          Nihilister has higher burst gen than other SRs due to her skills.
          Second shot onwards, Nihilister gets pierce, damages cover and Nikke
          and that counts as hitting 2 enemies, and then she deals extra damage
          to both cover and Nikke due to her skill 2, hitting 4 times every
          shot. A2 is a slower shooting RL than other RLs. However due to her
          base splash radius being higher than other RLs, she hits
          P1+P2+P3+cover of all 3, making her burst gen 6× the normal burst gen
          per shot. Pascal is an automatic RL. His base burst gen value is lower
          than other RLs but he shoots faster than them. Pascal shoots 6 bullets
          for every 3 bullets normal RLs shoot, however he is currently bugged
          and wastes one of his ammo for some odd reason by shooting it at the
          ground.
        </p>
        <h5>Info #12</h5>
        <p>
          Dual-wielders like Noah, Soline, Crow and Quency shoot only from one
          gun in PvP, and thus their burst gen is halved.
        </p>
        <h5>Info #13</h5>
        <p>
          RL and SR can enter cover between shots and{' '}
          <strong>can avoid incoming projectiles of opponent RLs</strong>. We
          call this phenomenon <strong>"Rocket whiffing"</strong> or "whiffing"
          or “i-framing”. If this event occurs, the fire focus party will lose
          tremendous amounts of burst gen, and this can be the deciding factor
          of a win or a loss in 95% of matchups.
        </p>
        <SectionHeader
          title="Burst Generation Terminology"
          subtitle="Learn common Burst gen terms."
        />
        <h5>Info #14</h5>
        <p>
          Since the burst gen of different Nikkes varies a lot, weapons that can
          generate a large amount of burst gen are often used as measurement
          units to calculate the burst gen of a particular team. These weapons
          are Rocket launchers and Clip shotguns. Without taking into account
          the charging terms, the approximate burst gen speed ranking is:
        </p>
        <Alert variant="primary">
          <p>
            2 shots of 1s charge time RL (fastest) &gt; 5 shots of Clip SG (very
            fast) &gt; 3 shots of 1s charge time RL (fast) (≈ 6 shots of clip SG
            ) &gt; 7 shots of Clip SG (slow) &gt; 4 shots of 1s charge time RL
            (very slow) (≈ 8 shots of Clip SG).
          </p>
        </Alert>
        <p>This will be discussed more in the Burst Energy Generation Guide.</p>
        <h5>Info #15</h5>
        <p>
          A high-speed team generally refers to a team that can reach full burst
          when the third shot of "1s charge time RL" is hit, abbreviated as:
          <strong>“3RL team”</strong>; a very high-speed team generally refers
          to a team that can be fully charged with 2 shots of "1s charge time
          RL". The teams that can reach full burst when the second shot hits
          opponents, are referred to as: <strong>“2 RL team”</strong>. Teams
          that can reach full burst when Clip SGs shoot 5 times are referred to
          as: <strong>“5SG or 2.5RL team”</strong>. The term 2.5RL is used since
          they burst between 2RL and 3RL teams, and so on.
        </p>
        <h5>Info #16</h5>
        <p>
          Teams that are slower than 4RL are not recommended for use, since they
          take too long to give any positive output. Instead of those teams,
          just use your highest CP Nikkes that are left by pressing ‘auto’.
        </p>
        <SectionHeader title="Important Additional Basics" />
        <h5>Info #17</h5>
        <p>
          The hit-rate attribute is useless in PvP. Even shotguns, who have the
          worst hit-rate, will shoot and hit all 10 of their pellets, and not
          miss. Additionally, Nikkes have no core.
        </p>
        <h5>Info #18</h5>
        <p>
          <strong>Combat Power (CP) penalty exists in PvP</strong>, but within a
          certain range, the matchups are completely normal. When the combat
          power difference is greater than 15.4%, the low CP team will have a
          20% attribute penalty, which will increase linearly thereafter. When
          the combat power difference is less than 15.4%, there is no penalty.
          Refer to the graph for accurate representation of the CP penalty.
        </p>
        <StaticImage src="../../../images/nikke/pvp/pvp_16.webp" alt="PVP" />
        <h5>Info #19</h5>
        <p>
          There is almost no skill delay in the arena, such as Privaty's stun,
          Scarlet’s AOE, Anne:Miracle Fairy's attack buff, etc., they all take
          effect immediately.
        </p>
        <h5>Info #20</h5>
        <p>
          Players should know a key difference between invincibility and
          Indomitability:
        </p>
        <ul>
          <li>
            <strong>Indomitability (Blanc or Makima)</strong>: Will keep taking
            damage throughout the duration of Indomitability, which means jackal
            linked Nikkes still take damage, and opponents with “Restores x% of
            attack damage as HP” skills will still heal their HP.{' '}
          </li>
          <li>
            <strong>Invincibility (Noah or Biscuit+Defender)</strong>: Will not
            take any damage during Invincibility duration, which means jackal
            linked Nikkes will not take damage, and opponents with “Restores x%
            of attack damage as HP” Skills will not be able to heal their HP.
          </li>
        </ul>
        <h5>Info #21</h5>
        <p>
          PvP battles happen at 30FPS, hence the fire rate for each gun type is
          as follows:
        </p>
        <StaticImage src="../../../images/nikke/pvp/pvp_17.webp" alt="PVP" />
        <p id="targeting">
          This is important for burst generation and feed charts.
        </p>
        <SectionHeader title="Factors Affecting Normal Targeting" />
        <p>
          I would like to thank the MVP: <strong>cleancascade</strong>, the
          amazing researcher: <strong>Keripo</strong> and Prydwen’s very own
          Coach and Twitch streamer: <strong>kisenix</strong> for their help in
          testing and also in defining this section.
        </p>
        <p>
          As shown in point 2 of The Basics, the normal targeting is taken as
          follows:
        </p>
        <ul>
          <li>Attacking SMG, MG, SG, AR and RL will focus on P1.</li>
          <li>Attacking SR will focus p5.</li>
          <li>Defending SG will focus on P5.</li>
          <li>Defending SMG, MG, AR, RL and SR will focus on P1.</li>
        </ul>
        <p>
          However there are multiple factors that can distract targeting. Normal
          factors include <strong>Taunting</strong> only. Abnormal factors
          include{' '}
          <strong>
            Target Skipping, Residual Targeting and Alice’s feelings
          </strong>
          .
        </p>
        <SectionHeader title="Taunting" />
        <p>
          Important Taunters (in order of importance - left being most
          important):
        </p>
        <ul>
          <li>B1: Noise, Ludmilla</li>
          <li>B2: Noah, Nero, Makima</li>
          <li>B3: -</li>
        </ul>
        <p>
          Taunting is an effect placed on enemies or a debuff placed on your own
          Nikke where all or some of your opponents are forced to target/focus
          the Nikke causing the taunt. This is a highly valuable skill that can
          allow you to either: soak up damage so that your main DPS doesn’t get
          killed, or completely nullify any damage, usually done by pairing
          “Defender” taunter+Biscuit.{' '}
        </p>
        <p>
          Taunters should ideally occupy P1 or P5, because you do not want to
          give extra burst gen to opponent RL. Of the important taunters listed,
          only Noise is not a defender. All the rest can possibly be used
          together with Biscuit to completely nullify opponents' damage (usually
          Alice, SG or 2B’s damage) in most cases, especially with Noah. Here
          are some short descriptions on when to use each taunter:
        </p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="noise" enablePopover />
        </div>
        <p>
          Noise acts as a Taunter+Healer. Mostly{' '}
          <strong>used to survive AoE damage</strong> of low buffed Scarlets or
          other weak AoEs. Can only taunt 2 enemies (or in rare cases, 3
          enemies).
        </p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="noah" enablePopover />
        </div>
        <p>
          Noah acts as a Tanking Taunter+Shielder. Mostly{' '}
          <strong>used to negate AoE damage completely</strong>. Also commonly
          used to taunt all enemies to herself for 10 seconds. One of the 3 long
          duration taunters. Can be paired with Biscuit to tank even more
          damage, usually nullifying an enemy Alice completely.
        </p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="ludmilla" enablePopover />
        </div>
        <p>
          Ludmilla acts as an off burst Tanking Taunter. You can burst with her
          if you like, however, you need to reach full burst to activate taunt.{' '}
          <strong>Usually used to allow Biscuit to burst</strong>, or to nullify
          enemy Alice or other single target damage dealers. One of the 3 long
          duration taunters. Can be paired with Biscuit to nullify 2B AoE.
          Cannot defend against other AoE. xRupee or Tia can be used as a
          Ludmilla replacement in P1 only, however they need to be paired with
          another B1.
        </p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="nero" enablePopover />
        </div>
        <p>
          Nero acts as a Tanking Taunter.{' '}
          <strong>Usually paired with Biscuit</strong> to nullify enemy Alice or
          other single target damage dealers. Can be paired with Biscuit to
          nullify 2B AoE. One of the 3 long duration taunters. Cannot defend
          against other AoE.
        </p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="makima" enablePopover />
        </div>
        <p>
          Makima is a Tanker with indomitability. Usually used in attacking.
          Mainly used in P5 while attacking to act as a tanker instead of a
          taunter to <strong>completely nullify Defending shotguns</strong>.
          Highly inefficient taunt. Rarely manages to block Enemy Alice or other
          single target damage dealers focusing on positions other than where
          Makima is placed at. Cannot defend against AoE.
        </p>
        <p>Now we move on to Abnormal factors.</p>
        <SectionHeader title="Target Skipping" />
        <p>
          Target skipping was initially described in point 5, with the reason
          being point 4. But for clarity, I will be reiterating what it is.
          Whenever a Nikke getting focused enters cover, the fire focusing party
          will retarget towards a Nikke who is not in cover.
        </p>
        <p>
          A Nikke may <strong>enter cover</strong> when: reloading, being
          stunned, or <strong>during RL and SR normal attack intervals</strong>,
          etc. Hence, if you are using Privaty+SGs in defence, a few times you
          will win against Makima keeping your shotguns stuck in P5 due to
          Privaty’s stun. You will still probably lose this matchup most of the
          time, because{' '}
          <strong>non-RL/SR weapon types rarely get target skipped</strong>.
        </p>
        <p>
          Regardless, this has adverse effects, which may lead to your main DPS
          (e.g. Scarlet) being focused down and killed before bursting. It can
          also lead to your burst chain breaking. For example: if you had Noah
          in P1 followed by Pepper as the sole B1 unit in your team at P2. Noah
          can get target skipped and Pepper gets focused instead. If she dies
          you will fail to reach full burst and there's a high probability you
          will lose that matchup now. If the enemy team has RL, it gives them
          extra burst gen as those RL will hit 3 Nikkes instead of the usual 2.
        </p>
        <p>
          As remarked before, RL and SR units are usually not suitable for P1
          unless you have a foolproof plan setup to avoid your main damage
          dealer from dying. RL or SR have the same threat of target skipping
          when in p5, if they're up against Defending SG units, or attacking SR
          units.
        </p>
        <p>
          <strong>Target skipping is still a random event</strong>. The Nikke is
          either skipped or not depending on what the game feels like choosing.{' '}
          <strong>
            More often than not, target skipping will occur against RL/SR.
          </strong>
        </p>
        <p>
          Hence, when using RL units in P1, it is usually ideal to use
          preventive measures against this. Some of them are:
        </p>
        <ul>
          <li>Use of Jackal to allow main DPS to be more tankier,</li>
          <li>
            Revive the unit who may die with Rapunzel or Anne:Miracle Fairy,
          </li>
          <li>Use full RL/SR team so no one gets target skipped,</li>
          <li>
            Use an extra unit of the same burst type that cannot get skipped to
            allow for full burst (for example: Noah P1 + Viper P2; or Noise P1 +
            Pepper P2), so that Viper or Pepper die instead of Noah or Noise.
          </li>
        </ul>
        <p>
          These are some general ideas to give you an example of what you can do
          in order to not fall prey to Target Skipping.
        </p>
        <SectionHeader title="Residual Targeting" />
        <p>
          Residual Targeting, a rare event in which the entire targeting for one
          or more teams is shifted toward a certain position. To explain,
          Residual Targeting means targeting that is carried over from the
          previous round <strong>(hence, it exists in SPvP only)</strong>. In
          this, the position on which the last nikke dies (or was the last Nikke
          to get hit with a bullet) in the preceding round will be first
          targeted in the subsequent round. For instance, if all of your Round 2
          units died, but in the END, your P4 died, there's a high chance P4 of
          your next round team will be targeted next by the entire enemy squad.{' '}
        </p>
        <p>
          The problem is: it is not possible for us to control its outcome.
          However, we can curb its effect to some extent by swapping the team
          orders (Round 3 becomes Round 1, and vice versa) of teams we know are
          possibly quick and efficient, or have an indomitable Nikke in an
          appropriate position to avoid exposing core units, or by introducing
          target skipping. There is no proof yet whether RLs/SRs can evade
          residual targeting through Target Skipping, but there's a high chance
          they do because of "going into cover".
        </p>
        <p>
          Residual Targeting can happen to defenders and attackers both,
          confirmed through testing.
        </p>
        <p>
          You can use this to your advantage when attacking, but the adoption is
          a bit clunky and hard to execute. Also, residual targeting is a rare
          occurrence, it is random just like target skipping. However, unlike
          target skipping{' '}
          <strong>
            more often than not, Residual Targeting will NOT occur.
          </strong>
        </p>
        <SectionHeader
          title="Alice’s feelings"
          subtitle="Alice having abnormalities in targeting"
        />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="alice" enablePopover />
        </div>
        <p>
          If you plan to research targeting, we highly recommend you do not
          start with Alice. Alice has a mind of her own sometimes. Here’s just
          some of the stuff we have found over the months of what can or cannot
          happen with Alice:
        </p>
        <ul>
          <li>
            She will sometimes ignore Noah’s taunt on burst completely, and
            sometimes she will be taunted. However, if Noah or Noise hit Alice
            with their full charged shot, Alice WILL get taunted.
          </li>
          <ul>
            <li>
              If Alice is not taunted by ‘taunt on burst’, she will continue to
              attack the Nikke she was focusing on until either the target is
              dead, or if she was taunted by a ‘fully-charged shot taunt’ by a
              Nikke.
            </li>
            <li>This has only been observed for attacking Alice.</li>
          </ul>
          <li>
            She will get stuck on Makima sometimes and lose, but often she will
            ignore Makima after Makima’s short taunt duration ends and then kill
            the rest of your team in the blink of an eye.
          </li>
          <ul>
            <li>
              This has mainly been observed for defending Alice, where Makima
              may taunt Alice, but after 3 seconds when the taunt is over, Alice
              will stop focusing on Makima completely until the end.
            </li>
          </ul>
          <li>
            She has no set attacking pattern. After her target is killed, she
            will randomly aim for a Nikke to start shooting at, unlike other
            Nikkes such as shotguns who usually, for example, while defending:
            will focus on p5, then p4, then p3 and so on.
          </li>
          <li>
            There is an unusual bug in which the team{' '}
            <strong>“Noah - Rapunzel - Alice - Anis - Biscuit”</strong> does not
            burst in 3RL (like it should) even without visual rocket whiffing,
            unless we use 2 quantum cubes (which we used on Anis and one other
            RL unit).
          </li>
          <ul>
            <li>
              We suspect it is happening because Burst Gen Speed Value of teams
              calculated between 100 - 102 in our way of calculating burst gen
              happen to burst the next time a Nikke will provide burst gen to
              them. Hence, try to reach &gt; 102 burst speed value for your
              teams.
            </li>
            <li>
              Sometimes, using ARs/SMGs in teams that are that are 100-102 can
              allow teams to burst normally due to them providing burst gen at a
              faster rate than RLs/SRs can allow teams to burst.
            </li>
          </ul>
        </ul>
        <p>
          All in all, when going up against Alice, it's best to hope that Alice
          will follow normal targeting patterns. Alice’s main weakness still
          remains to be Biscuit+Defender class taunter (if the taunter
          successfully taunts Alice), but her importance in attack cannot be
          understated. She is definitely one of the strongest units in PvP, and
          hence worth studying.
        </p>
        <SectionHeader title="Known Bugs in PvP" />
        <ul>
          <li>Cover Bug, exists in SP Arena:</li>
          <ul>
            <li>
              If the cover of a Nikke is completely destroyed in a Round, the
              following Round will also have the cover destroyed, hence reducing
              enemy RLs Burst Gen from 4× to 2×. [FIXED in September 1 Patch]
            </li>
          </ul>
          <li>Revive Bug, exists in SP and Rookie Arena:</li>
          <ul>
            <li>
              If a Nikke is revived, the OL stats are reapplied on the Nikke and
              hence, doubled. For example: If a Scarlet had 40 Ammo and she
              died. When she is revived, she will have 60 ammo. [FIXED]
            </li>
          </ul>
          <li>
            Alice ignoring ‘taunt on burst’ of Noah, exists in SP and Rookie
            Arena:
          </li>
          <ul>
            <li>
              Alice will straight up ignore Noah’s taunt and keep shooting where
              she was initially shooting unless the Nikke she was shooting at
              dies or she is taunted by another Nikke (for example, by Noise’s
              or Noah’s Taunt on Full Charge Shot). This Bug is extremely common
              but experience varies from account to account on whether it
              happens or not. [Not Fixed] Video:{' '}
              <a
                href="https://www.youtube.com/watch?v=IsD-eXPSGZ4"
                target="_blank"
                rel="noreferrer"
                className="social-media-link"
              >
                Kosmiu’s video.
              </a>{' '}
            </li>
          </ul>
          <li>
            Opponent Defenders getting Immune from Player’s own Biscuit, exists
            in SP and Rookie Arena:
          </li>
          <ul>
            <li>
              Quite an insane bug, one must say. Let’s say you are facing an
              Opponent who has `Defender` type Nikkes like Jackal but no
              Biscuit. Meanwhile your team is a Biscuit+SAnis or
              Biscuit+Defender setup. Very very rarely, the opponent defenders
              will get Immunity/Invincibility from your OWN biscuit. [Not Fixed]
            </li>
          </ul>
        </ul>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="rOpy7j41dq4" />
          </Col>
        </Row>
        <ul>
          <li>Pascal hates the ground:</li>
          <ul>
            <li>
              Pascal straight up just shoots his first bullet at the ground,
              causing him to lose significant Burst Gen.
            </li>
          </ul>
        </ul>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="lS8RWr89dOg" />
          </Col>
        </Row>
        <ul>
          <li>Bizarre Target Skipping, exists in SP and Rookie Arena:</li>
          <ul>
            <li>
              There is also a recent find in which the Nikke in defending team’s
              P5 might skip P1 to attack P2 (or if it’s an SG unit: skips P5 to
              attack P4), regardless of P1 being RL or not. This is a very rare
              occurrence, the reason of which we have not found at all yet.{' '}
            </li>
            <li>
              This occurrence has happened in round 1 and rookie, hence it is
              not related to residual targeting.
            </li>
          </ul>
        </ul>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="zPg3YbSpK4k" />
          </Col>
        </Row>
        <p>
          I believe all the major mechanics have now been adequately described.
          This is everything we have found out about PvP till Tia’s banner, and
          I can only hope I did not miss any key points worth knowing. Do
          contact us in the{' '}
          <a
            href="https://discord.com/invite/Gpt2KykNpw"
            target="_blank"
            rel="noreferrer"
            className="social-media-link"
          >
            Nikke Community Server
          </a>{' '}
          or Prydwen’s very own Discord Server if you want to discuss PvP with
          us!
        </p>
        <p>
          Next up should be the very important Burst Generation Guide, after
          which we will move on to what PvP team-building is all about.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="PvP Burst Generation"
            link="/nikke/guides/pvp-burst"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_pvp3.jpg"
                alt="PvP Burst Generation"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkePVPMech;

export const Head: React.FC = () => (
  <Seo
    title="PvP Arena Mechanics | NIKKE | Prydwen Institute"
    description="Everything you need to know about PVP in NIKKE."
    game="nikke"
  />
);
